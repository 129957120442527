<template>
    <div class="p-4">
      <div class="font-bold text-xl mb-4">Route Schedule</div>
      <div v-for="(bus, index) in buses" :key="index" class="relative mb-6 border p-4 rounded-lg">
        <div class="flex items-center justify-between mb-2">
          <div class="flex items-center space-x-4">
            <div class="text-lg font-bold text-yellow-500">{{ bus.name }}</div>
            <div class="flex items-center space-x-2">
              <label class="font-semibold">Driver:</label>
              <select class="border p-1 rounded w-32">
                <option v-for="driver in drivers" :key="driver">{{ driver }}</option>
              </select>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-around space-x-4">
            <div class="flex items-center space-x-2 w-1/5">
              <label class="font-semibold">Schedule:</label>
              
                <div>Monday - Friday</div>
             
            </div>
            <div class="flex items-center space-x-2 w-1/5">
              <label class="font-semibold whitespace-nowrap">Pickup Time:</label>
              <select class="border p-1 rounded w-full">
                <option v-for="time in times" :key="time">{{ time }}</option>
              </select>
            </div>
            <div class="flex items-center space-x-2 w-1/5">
              <label class="font-semibold whitespace-nowrap">Drop Off Time:</label>
              <select class="border p-1 rounded w-full">
                <option v-for="time in times" :key="time">{{ time }}</option>
              </select>
            </div>
            <button class="bg-green-500 text-white px-3 py-1 rounded w-32">Apply All</button>
          </div>
        </div>
        <transition name="accordion">
          <div v-show="bus.expanded" class="accordion-content">
            <div class="flex items-center justify-between mb-2">
              <div class="w-1/6"></div>
              <div class="w-1/6 font-semibold">Driver</div>
              <div class="w-1/6 font-semibold">Pickup Time</div>
              <div class="w-1/6 font-semibold">Drop Off Time</div>
              <div class="w-1/6"></div>
            </div>
            <div v-for="day in days" :key="day" class="flex items-center justify-between mb-2">
              <div class="w-1/6 text-yellow-500 text-right">{{ day }}</div>
              <div class="w-1/6">
                <select class="border p-1 rounded w-full">
                  <option v-for="driver in drivers" :key="driver">{{ driver }}</option>
                </select>
              </div>
              <div class="w-1/6">
                <select class="border p-1 rounded w-full">
                  <option v-for="time in times" :key="time">{{ time }}</option>
                </select>
              </div>
              <div class="w-1/6">
                <select class="border p-1 rounded w-full">
                  <option v-for="time in times" :key="time">{{ time }}</option>
                </select>
              </div>
              <div class="w-1/6 flex items-center space-x-2">
                <input type="checkbox" class="border p-1 rounded">
                <label class="font-semibold">Active</label>
              </div>
            </div>
          </div>
        </transition>
        <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <button @click="toggleBus(index)" class="bg-gray-300 rounded-full w-8 h-8 flex items-center justify-center">
            <span v-if="bus.expanded">▲</span>
            <span v-else>▼</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const buses = ref([
    { name: 'Bus #100', expanded: true },
    { name: 'Bus #101', expanded: false },
    { name: 'Bus #102', expanded: false },
    { name: 'Bus #103', expanded: false },
    { name: 'Bus #104', expanded: false }
  ]);
  
  const drivers = ['Driver 1', 'Driver 2', 'Driver 3'];
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const times = ['08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM'];
  
  function toggleBus(index) {
    buses.value[index].expanded = !buses.value[index].expanded;
  }
  </script>
  
  <style scoped>
  .accordion-enter-active, .accordion-leave-active {
    transition: all 0.3s ease;
  }
  .accordion-enter, .accordion-leave-to {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  </style>
  